<template>
	<b-card v-if="data" no-body class="card-revenue-budget">
		<b-row class="mx-0">
			<b-col class="revenue-report-wrapper">
				<div class="d-sm-flex justify-content-between align-items-center mb-3">
					<h4 class="card-title mb-50 mb-sm-0">
						Yearly view
					</h4>
					<div class="d-flex align-items-center">
						<div class="d-flex align-items-center mr-2">
							<span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
							<span>New</span>
						</div>
						<div class="d-flex align-items-center ml-75">
							<span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
							<span>Closed</span>
						</div>
					</div>
				</div>

				<!-- chart -->
				<vue-apex-charts id="revenue-report-chart" type="bar" height="230" :options="chartOptions" :series="data" />
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import { $themeColors } from '@themeConfig';
import Ripple from 'vue-ripple-directive';

export default {
	components: {
		VueApexCharts,
		BCard,
		BRow,
		BCol,
	},
	directives: {
		Ripple,
	},
	props: {
		data: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			chartOptions: {
				chart: {
					type: 'bar',
					toolbar: { show: false },
				},
				grid: {
					padding: {
						top: -20,
						bottom: -10,
					},
					yaxis: {
						lines: { show: true },
					},
				},
				xaxis: {
					categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
					labels: {
						style: {
							colors: '#6E6B7B',
							fontSize: '0.86rem',
							fontFamily: 'Montserrat',
						},
					},
					axisTicks: {
						show: false,
					},
					axisBorder: {
						show: false,
					},
				},
				legend: {
					show: false,
				},
				dataLabels: {
					enabled: true,
				},
				colors: [$themeColors.primary, $themeColors.warning],
				plotOptions: {
					bar: {
						columnWidth: '60%',
						endingShape: 'rounded',
					},
					distributed: true,
				},
				yaxis: {
					labels: {
						style: {
							colors: '#6E6B7B',
							fontSize: '0.86rem',
							fontFamily: 'Montserrat',
						},
					},
				},
			},
		};
	},
};
</script>
