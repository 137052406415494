<template>
	<section id="dashboard-ecommerce">
		<b-row class="match-height">
			<b-col>
				<status-statistics :data="status" />
			</b-col>
		</b-row>

		<yearly-view :data="yearly" />
	</section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';

import store from '@/store';
import StatusStatistics from './StatusStatistics.vue';
import YearlyView from './YearlyView.vue';

export default {
	name: 'Dashboard',

	beforeRouteEnter(to, from, next) {
		const { user } = store.state.auth;
		if ([4, 10].includes(user.type)) {
			next();
		} else {
			next({ name: 'grm.complaints' });
		}
	},

	components: {
		BRow,
		BCol,
		StatusStatistics,
		YearlyView,
	},

	data() {
		return {
			statistics: null,
		};
	},

	computed: {
		status() {
			return [
				{
					icon: 'TrendingUpIcon',
					color: 'light-primary',
					title: this.statistics?.status.new ?? 0,
					subtitle: 'New',
					customClass: 'mb-2 mb-xl-0',
				},
				{ icon: 'UnlockIcon', color: 'light-info', title: this.statistics?.status.open ?? 0, subtitle: 'Open', customClass: 'mb-2 mb-xl-0' },
				{
					icon: 'LoaderIcon',
					color: 'light-danger',
					title: this.statistics?.status.waiting ?? 0,
					subtitle: 'Waiting response',
					customClass: 'mb-2 mb-sm-0',
				},
				{ icon: 'LockIcon', color: 'light-success', title: this.statistics?.status.closed ?? 0, subtitle: 'Closed', customClass: '' },
			];
		},

		yearly() {
			return [
				{ name: 'New', data: this.statistics?.newByMonth ?? [] },
				{ name: 'Closed', data: this.statistics?.closedByMonth ?? [] },
			];
		},
	},

	async created() {
		this.statistics = await store.dispatch('complaints/stats');
	},
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
